import React from "react";

const Footer = () => {

  return (
    <footer id="contact">
      <div className="container mx-auto">
        <div className="mt-2 md:mb-20 mb-10">
          <hr className="text-neutral-300"></hr>
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12">
          <div className="lg:col-span-6 md:pr-24">
            <h3 className="font-display text-display-sm font-normal pb-4">
              How to get started.
            </h3>
          <div> Please contact us to discuss your ideas, book your free site survey and get your new garden room underway.</div>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 xl:pl-80 lg:pl-48">
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Holyport Garden Rooms</p>
              <p className="text-body-sm font-light text-neutral-900">
                Homeland<br />Stroud Farm Road<br />Holyport<br />SL6 2LH
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Email us at
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600"
                href="mailto:hello@landify.design">
                info@holyportgardenrooms.com
              </a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Or call use at
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600"
                href="/">
                +44 7495 432474
              </a>
            </div>
          </div>
        </div>
        <div className="md:my-20 my-10">
          <hr className="text-neutral-300"></hr>
        </div>

      </div>
    </footer>
  );
};
export default Footer;
